import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
// import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { AuthContext } from '../auth/AuthContext';

export const Login = () => {
    const { login, error, loading } = useContext(AuthContext);
    const [showError, setShowError] = useState(false);
    // const [checked, setChecked] = useState(false);

    const toast = useRef(null);

    const defaultValues = {
        username: '',
        password: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit
    } = useForm({ defaultValues });

    useEffect(() => {
        if (error && showError) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: error });
            setShowError(false); // Reset error visibility after showing the message
        }
    }, [error, showError]);

    const onSubmit = async (data) => {
        setShowError(false); // Reset error visibility before attempting login
        const loginError = await login(data.username, data.password);

        if (loginError) {
            setShowError(true); // Muestra el mensaje de error
            console.log('Error de login:', loginError);
        } else {
            console.log('Inicio de sesión exitoso'); // Si no hay error, puedes hacer algo más
        }
    };

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    return (
        <div className="login-body">
            <Toast ref={toast} />
            <div className="card login-panel p-fluid">
                <div className="login-panel-content">
                    <div className="grid">
                        <div className="col-12 sm:col-6 md:col-6 logo-container">
                            <img src="assets/layout/images/logo-regatas.svg" alt="regatas" style={{ width: '180%', height: 'auto' }} />
                            <span className="guest-sign-in">Bienvenido, inicia sesión.</span>
                        </div>
                        <div className="col-12 username-container">
                            <span className="p-float-label">
                                <Controller
                                    name="username"
                                    control={control}
                                    rules={{ required: 'Username es requerido.' }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            id={field.name}
                                            {...field}
                                            autoFocus
                                            className={classNames({
                                                'p-invalid': fieldState.invalid
                                            })}
                                        />
                                    )}
                                />
                                <label htmlFor="username" className={classNames({ 'p-error': errors.username })}>
                                    Username*
                                </label>
                            </span>
                            {getFormErrorMessage('username')}
                        </div>
                        <div className="col-12 password-container">
                            <span className="p-float-label">
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{ required: 'Password es requerido.' }}
                                    render={({ field, fieldState }) => (
                                        <Password
                                            id={field.name}
                                            {...field}
                                            toggleMask
                                            className={classNames({
                                                'p-invalid': fieldState.invalid
                                            })}
                                        />
                                    )}
                                />
                                <label htmlFor="password" className={classNames({ 'p-error': errors.password })}>
                                    Password*
                                </label>
                            </span>
                            {getFormErrorMessage('password')}
                        </div>
                        {/* <div className="col-12 sm:col-6 md:col-6 rememberme-container">
                                <Checkbox checked={checked} onChange={(e) => setChecked(e.checked)} />
                                <label> Remember me</label>
                            </div> */}

                        {/* <div className="col-12 sm:col-6 md:col-6 forgetpassword-container">
                                <a href="/" className="forget-password">
                                    Forget Password
                                </a>
                            </div> */}

                        <div className="col-12 sm:col-6 md:col-6">
                            <Button label="Ingresar" icon="pi pi-check" onClick={handleSubmit(onSubmit)} disabled={loading} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
