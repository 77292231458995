import axios from 'axios';

const API_REGATAS = 'https://apiregatas2.regataslima.pe/';
// const API_REGATAS = 'http://localhost:3700/';

let configCache = null;

function getConfig() {
    if (configCache) {
        return Promise.resolve(configCache);
    }

    const token = localStorage.getItem('REG1');

    if (token) {
        configCache = {
            headers: {
                'x-token': token
            }
        };

        return Promise.resolve(configCache);
    } else {
        return Promise.reject(new Error('Token no encontrado'));
    }
}

export default class SharedService {
    async recuperarData(modelName, campo, valor) {
        try {
            const config = await getConfig();
            const params = {};

            if (campo !== undefined) {
                params.campo = campo;
            }

            if (valor !== undefined) {
                params.valor = valor;
            }

            const res = await axios.get(`${API_REGATAS}api/recuperar-data/${modelName}`, { params, ...config });
            return res.data;
        } catch (error) {
            console.error('Error al recuperar datos:', error);
            throw error;
        }
    }

    async insertManyData(modelName, documentos) {
        try {
            const config = await getConfig();
            const res = await axios.post(`${API_REGATAS}api/insertar-data/${modelName}`, documentos, config);
            return res.data;
        } catch (error) {
            return error;
        }
    }

    async actualizarData(modelName, documentos) {
        try {
            const config = await getConfig();
            const res = await axios.patch(`${API_REGATAS}api/actualizar-data/${modelName}`, documentos, config);
            return res.data;
        } catch (error) {
            return error;
        }
    }

    async getConsultaDOC(tipo, numero) {
        try {
            const res = await axios.get(`https://apisperu.net/api/${tipo}/${numero}`, {
                headers: {
                    Authorization: `Bearer e0184726b2bfcbffa5e34ecfb30b29736cdfd1c0d46a96f877cd51622ced0e13`
                }
            });
            return res.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            } else if (error.request) {
                return { error: 'No se recibió respuesta del servidor' };
            } else {
                return { error: 'Error en la solicitud' };
            }
        }
    }

    async getMembresia(carnet) {
        try {
            const res = await axios.get(`https://api.crl.pe/pool/membresia/${carnet}`);
            return res.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            } else if (error.request) {
                return { error: 'No se recibió respuesta del servidor' };
            } else {
                return { error: 'Error en la solicitud' };
            }
        }
    }

    async getCuenta(cardcode) {
        try {
            const res = await axios.get(`https://api.crl.pe/pool/estado-cuenta/${cardcode}`);
            return res.data;
        } catch (error) {
            if (error.response) {
                return error.response.data;
            } else if (error.request) {
                return { error: 'No se recibió respuesta del servidor' };
            } else {
                return { error: 'Error en la solicitud' };
            }
        }
    }
}
