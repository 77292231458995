import React, { useRef, useEffect, useContext } from 'react';
import { AuthContext } from '../auth/AuthContext';

const YOUR_ZENDESK_SUBDOMAIN = 'clubderegataslimahelp';

const Ticket = () => {
    const { zendesk } = useContext(AuthContext);
    const formRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (zendesk && inputRef.current) {
            inputRef.current.value = zendesk;
            console.log('Asignando valor a input', inputRef);
            formRef.current.submit();
        } else {
            console.log('zendesk es nulo o inputRef.current no está disponible');
        }
    }, [zendesk]);

    return (
        <form ref={formRef} action={`https://${YOUR_ZENDESK_SUBDOMAIN}.zendesk.com/access/jwt`} method="post">
            <input ref={inputRef} type="hidden" name="jwt"></input>
        </form>
    );
};

export default Ticket;
