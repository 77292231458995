import { Document, Packer, Paragraph, TextRun, AlignmentType, BorderStyle, Table, TableRow, TableCell, WidthType, UnderlineType, ExternalHyperlink } from 'docx';
import { saveAs } from 'file-saver';
import SharedService from '../service/shared.service';

const createWordDocument = async (data) => {
    const emptyParagraph = new Paragraph('');

    const descripcionHtml = data.descripcion; // Tu HTML con etiquetas <p>, <br>, <h2>, <h3>
    const descripcionparagraphs = descripcionHtml.split(/<\/?p\b[^>]*>|<br>/g).filter(Boolean);

    const descripcion = descripcionparagraphs.map((para) => {
        let text = para.trim();

        let alignment = AlignmentType.JUSTIFIED;
        let size = 24;
        let bold = false;
        let underline = undefined;

        // Aplicar estilos de encabezado
        if (text.includes('<h2')) {
            text = text.replace(/<\/?h2.*?>/g, '').trim();
            size = 30;
            bold = true;
            underline = { type: UnderlineType.SINGLE };
            alignment = AlignmentType.CENTER;
        } else if (text.includes('<h3')) {
            text = text.replace(/<\/?h3.*?>/g, '').trim();
            size = 28;
            bold = true;
            underline = { type: UnderlineType.SINGLE };
            alignment = AlignmentType.CENTER;
        }

        // Aplicar estilos de <strong> y <u>
        bold = bold || text.includes('<strong>');
        underline = underline || (text.includes('<u>') ? { type: UnderlineType.SINGLE } : undefined);

        // Limpiar etiquetas <strong> y <u> después de aplicar estilos
        text = text.replace(/<\/?(strong|u)>/g, '');

        return new Paragraph({
            children: [
                new TextRun({
                    text: text,
                    size: size,
                    bold: bold,
                    underline: underline
                })
            ],
            alignment: alignment
        });
    });

    for (let i = 1; i < descripcion.length; i += 2) {
        descripcion.splice(i, 0, new Paragraph(''));
    }

    const observacionHtml = data.observacion; // Tu HTML con etiquetas <p>
    const observacionparagraphs = observacionHtml.split(/<\/?p>/g).filter(Boolean);
    // Crear un array de Paragraphs
    const observacion = observacionparagraphs.map((para) => {
        return new Paragraph({
            children: [
                new TextRun({
                    text: para.trim(), // Limpiar espacios en blanco alrededor del texto
                    size: 24 // Tamaño de la fuente en half-points (24 = 12pt)
                })
            ],
            alignment: AlignmentType.JUSTIFIED
        });
    });

    const generatePage = (rowData) => {
        const proponenteParagraph = new Paragraph({
            children: [
                new TextRun({
                    text: rowData.proponente !== '' ? `${rowData.proponente}` : '',
                    size: 28,
                    bold: true
                })
            ],
            alignment: AlignmentType.LEFT
        });

        const numeracionText = new TextRun({
            text: rowData.tipo === 'ACUERDO' ? `ACUERDO N° ${rowData.nroacuerdo}` : `INFORME N° ${rowData.nroacuerdo}`,
            bold: true,
            underline: {
                type: UnderlineType.SINGLE
            },
            size: 24
        });

        const acuerdoParagraph = new Paragraph({
            children: [numeracionText]
        });

        // Contenido de las celdas
        const cell1_1 = new TableCell({
            children: [new Paragraph(rowData.tipo === 'ACUERDO' ? 'NRO. ACUERDO' : 'NRO. INFORME')],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });
        const cell1_2 = new TableCell({
            children: [new Paragraph(`${rowData.nroacuerdo}`)],
            width: { size: 50, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Contenido de las celdas
        const cell2_1 = new TableCell({
            children: [new Paragraph(rowData.tipo === 'ACUERDO' ? 'TIPO ACUERDO' : 'TIPO INFORME')],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });
        const cell2_2 = new TableCell({
            children: [new Paragraph(`${rowData.categoria}`)],
            width: { size: 50, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Contenido de las celdas
        const cell3_1 = new TableCell({
            children: [new Paragraph('C. COSTO')],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });
        const cell3_2 = new TableCell({
            children: [new Paragraph(`${rowData.centrocosto}`)],
            width: { size: 50, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Contenido de las celdas
        const cell4_1 = new TableCell({
            children: [new Paragraph(rowData.montosoles > 0 ? 'MONTO S/.' : 'MONTO $')],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });
        const cell4_2 = new TableCell({
            children: [new Paragraph(rowData.montosoles > 0 ? `${rowData.montosoles}` : `${rowData.montodolares}`)],
            width: { size: 50, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Contenido de las celdas
        const cell5_1 = new TableCell({
            children: [new Paragraph('LOCAL')],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });
        const cell5_2 = new TableCell({
            children: [new Paragraph(`${rowData.sede}`)],
            width: { size: 50, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Contenido de las celdas
        const cell6_1 = new TableCell({
            children: [new Paragraph('AREA SOLICITANTE')],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });
        const cell6_2 = new TableCell({
            children: [new Paragraph(`${rowData.area}`)],
            width: { size: 50, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Celda 1: Fila 1, Columna 1
        const cell7_1 = new TableCell({
            children: [new Paragraph('PRESUPUESTADO')],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });

        // Celda 2: Fila 1, Columna 2
        const cell7_2 = new TableCell({
            children: [new Paragraph(rowData.presupuestado ? 'SI' : 'NO')],
            width: { size: 50, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Celda 3: Fila 2, Columna 1
        const cell7_3 = new TableCell({
            children: [new Paragraph('PPTO. DISPONIBLE')],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });

        // Celda 4: Fila 2, Columna 2
        const cell7_4 = new TableCell({
            children: [new Paragraph(rowData.pptoDisponible ? 'SI' : 'NO')],
            width: { size: 50, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Celda 5: Fila 3, Columna 1
        const cell7_5 = new TableCell({
            children: [new Paragraph('TRANSFERENCIA')],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });

        // Celda 6: Fila 3, Columna 2
        const cell7_6 = new TableCell({
            children: [new Paragraph(rowData.transferencia ? 'SI' : 'NO')],
            width: { size: 50, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Contenido de las celdas
        const cell8_1 = new TableCell({
            children: [new Paragraph('NOTA')],
            width: { size: 23, type: WidthType.PERCENTAGE }
        });
        const cell8_2 = new TableCell({
            children: [new Paragraph(`${rowData.titulo}`)],
            width: { size: 77, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        // Contenido de las celdas
        const cell9_1 = new TableCell({
            children: [new Paragraph('DESCRIPCION')],
            width: { size: 23, type: WidthType.PERCENTAGE }
        });
        const cell9_2 = new TableCell({
            children: [new Paragraph(`${rowData.propuesta}`)],
            width: { size: 77, type: WidthType.PERCENTAGE },
            borders: {
                top: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                bottom: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                left: { style: BorderStyle.SINGLE, size: 4, color: '000000' },
                right: { style: BorderStyle.SINGLE, size: 4, color: '000000' }
            }
        });

        const row1 = new TableRow({ children: [cell1_1, cell1_2] });
        const row2 = new TableRow({ children: [cell2_1, cell2_2] });
        const row3 = new TableRow({ children: [cell3_1, cell3_2] });
        const row4 = new TableRow({ children: [cell4_1, cell4_2] });
        const row5 = new TableRow({ children: [cell5_1, cell5_2] });
        const row6 = new TableRow({ children: [cell6_1, cell6_2] });

        const row7_1 = new TableRow({ children: [cell7_1, cell7_2] });
        const row7_2 = new TableRow({ children: [cell7_3, cell7_4] });
        const row7_3 = new TableRow({ children: [cell7_5, cell7_6] });

        const row8 = new TableRow({ children: [cell8_1, cell8_2] });
        const row9 = new TableRow({ children: [cell9_1, cell9_2] });

        const table1 = new Table({
            rows: [row1],
            width: { size: 90, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.LEFT,
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const table2 = new Table({
            rows: [row2],
            width: { size: 90, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.LEFT,
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const table3 = new Table({
            rows: [row3],
            width: { size: 90, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.LEFT,
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const table4 = new Table({
            rows: [row4],
            width: { size: 90, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.LEFT,
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const table5 = new Table({
            rows: [row5],
            width: { size: 90, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.RIGHT,
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const table6 = new Table({
            rows: [row6],
            width: { size: 90, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.RIGHT,
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const table7 = new Table({
            rows: [row7_1, row7_2, row7_3],
            width: { size: 90, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.RIGHT,
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const table8 = new Table({
            rows: [row8],
            width: { size: 100, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.CENTER,
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const table9 = new Table({
            rows: [row9],
            width: { size: 100, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.CENTER,
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const cell1 = new TableCell({
            children: [table1, emptyParagraph, table2, emptyParagraph, table3, emptyParagraph, table4],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });

        const cell2 = new TableCell({
            children: [table5, emptyParagraph, table6, emptyParagraph, table7],
            width: { size: 50, type: WidthType.PERCENTAGE }
        });

        // Crear una fila con las dos celdas
        const row = new TableRow({
            children: [cell1, cell2],
            tableHeader: false
        });

        // Crear una tabla con una fila y dos columnas
        const table = new Table({
            rows: [row],
            width: { size: 100, type: WidthType.PERCENTAGE },
            alignment: AlignmentType.CENTER, // Alinear la tabla al centro
            borders: { top: { style: 'none' }, bottom: { style: 'none' }, left: { style: 'none' }, right: { style: 'none' } }
        });

        const linkParagraph =
            rowData.link !== ''
                ? new Paragraph({
                      children: [
                          new ExternalHyperlink({
                              children: [
                                  new TextRun({
                                      text: 'HAZ CLIC AQUÍ PARA VER EL ANEXO',
                                      style: 'Hyperlink',
                                      color: '0000FF',
                                      underline: {}
                                  })
                              ],
                              link: `https://regatasadmin.regataslima.pe/apidropzone/upload/${rowData.link}`
                          })
                      ],
                      alignment: AlignmentType.LEFT // Puedes ajustar la alineación según tus necesidades
                  })
                : null;

        const paragraphs = [emptyParagraph, proponenteParagraph, emptyParagraph, acuerdoParagraph, emptyParagraph, table, emptyParagraph, table8, emptyParagraph, table9, emptyParagraph];

        if (linkParagraph) {
            paragraphs.push(linkParagraph, emptyParagraph);
        }

        return paragraphs;

        // return [emptyParagraph, proponenteParagraph, emptyParagraph, acuerdoParagraph, emptyParagraph, table, emptyParagraph, table8, emptyParagraph, table9, emptyParagraph, linkParagraph, emptyParagraph];
    };

    try {
        const sharedService = new SharedService();
        const recuperarData = await sharedService.recuperarData('Gestion-acuerdo', 'actaModelo', data._id);
        // console.log('ACA', recuperarData);

        const filteredData1 = recuperarData.datos.filter((item) => item.estado === 'APROBADO' && item.tipo === 'ACUERDO' && item.publicado && !item.confidencial);
        const filteredData2 = recuperarData.datos.filter((item) => item.estado === 'APROBADO' && item.tipo === 'INFORME' && item.publicado && !item.confidencial);

        // console.log('Datos filtrados (acuerdo):', filteredData1);
        // console.log('Datos filtrados (informe):', filteredData2);

        const acuerdo = filteredData1.map((rowData) => generatePage(rowData)).flat();
        const informe = filteredData2.map((rowData) => generatePage(rowData)).flat();

        const children = [...descripcion, ...informe, ...acuerdo, ...observacion];

        // Crear el documento DOCX
        const doc = new Document({
            sections: [
                {
                    properties: {},
                    children: children
                }
            ],
            styles: {
                default: {
                    font: 'Arial'
                }
            }
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, `ACTA N° ${data.nroacta} PERIODO ${data.periodo}.docx`);
        console.log('Document created successfully');
    } catch (error) {
        console.error('Error creating document:', error);
    }
};

export default createWordDocument;
