import React, { useState, useEffect, useContext, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FileUpload } from 'primereact/fileupload';
import { Card } from 'primereact/card';

import { AuthContext } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';

/* by Fernando */
import SharedService from '../service/shared.service';

import axios from 'axios';
import dayjs from 'dayjs';

const modelosTemplates = {};

// Función para obtener la plantilla de objeto según el modelo seleccionado
const getEmptyResultado = (activeIndex) => {
    const modelName = modelosPorPagina[activeIndex]; // Suponiendo que tienes un array de modelosPorPagina
    return modelosTemplates[modelName] || {}; // Devuelve la plantilla correspondiente o un objeto vacío si no se encuentra
};

const modelosPorPagina = {
    0: 'Gestion-puerta',
    1: 'Gestion-puerta',
    2: 'Gestion-puerta',
    3: 'Gestion-puerta'
    // Agrega más páginas y modelos según sea necesario
};

const GestionPuerta = () => {
    /* by Fernando */
    const navigate = useNavigate();

    const { user, accesos } = useContext(AuthContext);
    const [activeIndex, setActiveIndex] = useState(0);

    const [inputValue, setInputValue] = useState('');
    const [resultados, setResultados] = useState([]);
    const [cuentacorriente, setCuentacorriente] = useState([]);
    const [selectedResultados, setSelectedResultados] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [updateKey, setUpdateKey] = useState(0);

    const toast = useRef(null);
    const dt = useRef(null);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            consultaPuertas();
        }
    };

    const actualizarResultadoSegunModelo = (activeIndex) => {
        const emptyResultadoModelo = getEmptyResultado(activeIndex);
        setResultados(emptyResultadoModelo);
    };

    useEffect(() => {
        // actualizarResultadoSegunModelo(activeIndex);
    }, [activeIndex, updateKey]);

    const consultaPuertas = async () => {
        let url = '';
        let params = '';

        // Validación y construcción de URL y parámetros
        if (/^[0-9]+$/.test(inputValue)) {
            if (inputValue.length <= 6) {
                params = `NroCarnet=${inputValue.padStart(6, '0')}`;
                url = `https://api.crl.pe/pool/membresia-carnet`;
            } else if (inputValue.length > 6 && inputValue.length < 9) {
                params = `NroDni=${inputValue.padStart(8, '0')}`;
                url = `https://api.crl.pe/pool/membresia-dni`;
            } else if (inputValue.length === 10 && inputValue.charAt(0) === '1') {
                params = `NroCarnet=${inputValue.substring(1, 7)}`;
                url = `https://api.crl.pe/pool/membresia-carnet`;
            }
        } else {
            params = `Apellido=${inputValue.toUpperCase()}`;
            url = `https://api.crl.pe/pool/membresia-apellido`;
        }

        // Llamada a la API
        try {
            const response = await axios.post(url, params, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            });

            setResultados(response.data);

            // Filtrado de resultados
            const filtro = response.data.filter((item) => item.parentesco === 'TITULAR' && !item.anulado);
            console.log('Resultados filtrados:', filtro);

            // Si hay resultados en filtro, realiza otra consulta
            if (filtro.length) {
                const codigo = filtro[0].cardcode ? filtro[0].cardcode : filtro[0].codigo;
                console.log('CODIGO', codigo);
                await realizarConsultaCuenta(codigo);
            }
        } catch (error) {
            console.error('Error en la búsqueda:', error);
        }
    };

    // Segunda consulta para estado de cuenta
    const realizarConsultaCuenta = async (codigo) => {
        const url = `https://api.crl.pe/pool/estado-cuenta/${codigo}`;

        try {
            const response = await axios.get(url);
            const filtroCuenta = response.data.filter((d) => {
                const diffMonths = dayjs().diff(dayjs(d.U_ST_CRL_FVENC), 'month');
                const daysOverdue = dayjs().diff(dayjs(d.U_ST_CRL_FVENC).add(diffMonths, 'month'), 'day');

                return diffMonths > 4 || (diffMonths === 4 && daysOverdue >= 1);
            });

            setCuentacorriente(filtroCuenta);
            console.log('Resultados cuenta corriente:', filtroCuenta);

            if (filtroCuenta.length) {
                alert('Info: El asociado actualmente presenta obligaciones pendientes de pago.');
            }
        } catch (error) {
            console.error('Error en la consulta de cuenta:', error);
        }
    };

    const placeholders = [
        'Ingrese número de Carné',
        'Ingrese número de DNI',
        'Ingrese número de DNI'
        // Añade más textos según lo necesites
    ];

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <InputText
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={placeholders[activeIndex] || 'Ingrese código'} // Muestra placeholder dinámico
                />
                <Button label="Ejecutar" onClick={consultaPuertas} className="p-button-outlined p-ml-2" />
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        const condition = true;

        return <React.Fragment>{condition && <Button label="Nuevo" icon="pi pi-plus" className="p-button-success" />}</React.Fragment>;
    };

    const getHeaderTitle = () => {
        switch (activeIndex) {
            case 0:
                return 'Control de puertas membresía';
            case 1:
                return 'Control de puertas invitado';
            case 2:
                return 'Control de puertas ajeno';
            case 3:
                return 'Control de puertas proveedores';
            default:
                return '';
        }
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{getHeaderTitle()}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const isRowSelectable = (event) => !event.data.anulado && event.data.status;

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <ConfirmDialog />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="MEMBRESÍA">
                            <DataTable
                                ref={dt}
                                value={resultados}
                                selection={selectedResultados}
                                onSelectionChange={(e) => setSelectedResultados(e.value)}
                                key={updateKey}
                                dataKey="_id"
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25]}
                                globalFilter={globalFilter}
                                header={header}
                                responsiveLayout="scroll"
                                className="datatable-responsive"
                                isDataSelectable={isRowSelectable}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                emptyMessage="No hay registros"
                            >
                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                <Column field="carnet" header="CARNÉ" alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="nombre" header="NOMBRE" alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="carnet" header="FOTO" alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="parentesco" header="PARENTESCO" alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="tipopago" header="CATEGORIA" alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="status" header="ESTADO" alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>

                                {/* <Column field="nroacta" header="Nro. acta" dataType="numeric" sortable body={nroactaBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column> */}
                                {/* <Column field="periodo" header="Período" sortable body={periodoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column> */}
                                {/* <Column
                                    field="fechaActa"
                                    header={<span className="p-column-title">Fecha acta</span>}
                                    dataType="date"
                                    sortable
                                    body={(rowData) => fechaBodyTemplate(rowData.fechaActa)} // Pasa solo el valor de fechaActa
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                ></Column> */}
                                {/* <Column
                                    field="fechaCierre"
                                    header={<span className="p-column-title">Fecha de cierre</span>}
                                    dataType="date"
                                    sortable
                                    body={(rowData) => horaBodyTemplate(rowData.fechaCierre)} // Pasa solo el valor de fechaCierre
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                ></Column> */}
                                {/* <Column field="observacion" header="Observación" sortable body={observacionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                                {/* <Column field="usuarioCreado" header="Creador" sortable body={usuarioBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column> */}
                                {/* <Column
                                    field="fechaCreado"
                                    header={<span className="p-column-title">Fecha creado</span>}
                                    dataType="date"
                                    sortable
                                    body={(rowData) => fechaBodyTemplate(rowData.fechaCreado)} // Pasa solo el valor de la fecha
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                ></Column> */}
                                {/* <Column field="publicado" header="Publicado" body={publicadoBodyTemplate} sortable alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                {tieneAccesoModuloOConNivel4() && <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }}></Column>} */}
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="INVITADO"></TabPanel>
                        <TabPanel header="AJENO"></TabPanel>
                        <TabPanel header="PROVEEDOR"></TabPanel>
                    </TabView>
                </div>
            </div>
        </div>
    );
};

export default GestionPuerta;
