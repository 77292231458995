import React, { useState, useEffect, useContext, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { FileUpload } from 'primereact/fileupload';
import { Card } from 'primereact/card';

import { AuthContext } from '../auth/AuthContext';
import { useNavigate } from 'react-router-dom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

/* by Fernando */
import SharedService from '../service/shared.service';
import createWordDocument from './GestionWordHtml';

const tipo = ['ACUERDO', 'INFORME'];
const estado = ['REGISTRADO', 'ACTUALIZADO', 'EN REVISIÓN', 'OBSERVADO', 'MODIFICADO', 'CANCELADO', 'APROBADO'];
const situacion = ['PENDIENTE', 'EN EJECUCIÓN', 'CULMINADO'];
// const sede = ['CHORRILLOS', 'LA PUNTA', 'LA CANTUTA', 'SAN ANTONIO', 'VILLA DEPORTIVA', 'PARACAS'];

const modelosTemplates = {
    'Gestion-acta': {
        periodo: '2024-2026',
        nroacta: 0,
        fechaActa: new Date(),
        fechaCierre: new Date(),
        descripcion:
            '<h2 style="text-align: center;"><strong><u>ACTA N° XXX-XX</u></strong></h2><p>&nbsp;</p><h3 style="text-align: center;"><strong><u>SESIÓN DE CONSEJO DIRECTIVO DEL XX DE XXXXX DE XXXX</u></strong></h3><p>&nbsp;</p><p style="text-align: justify;">Reunidos en la sala de sesiones del Consejo Directivo, ubicada en el segundo piso del pabellón social de la sede de Chorrillos, sito en la Av. Chachi Dibós 1201 distrito de Chorrillos, Provincia y Departamento de Lima, siendo las diecinueve horas del día xxxxxxxxxx, bajo la Presidencia del señor Rafael Michel Guarderas Radzinsky con DNI. 10811735 y con la asistencia de los señores: Kelvin Henry Brain Rovira, Vicepresidente con DNI. 07272070; Eduardo José Atilio Laos de Lama, Director Secretario con DNI. 07700630; María del Rosario Quiroga Glave, Directora Pro Secretaria con DNI. 09750861; Rafael Adrián Sevilla Almeida, Director Tesorero con DNI. 09535309; Emilio Antonio Fantozzi Temple, Director Pro Tesorero con DNI. 10278312; Nicanor Eduardo Revilla Vergara, Director de Remo con DNI. 08224542; Fernando Enrique Bazo Costa, Director de Deportes Náuticos con DNI. 07829182; Alberto Manuel Siles Vascones, Director de Deportes con DNI. 07834980; Rodolfo Javier La Torre Osterling, Director de Deportes con DNI. 08804023; Julio Edgardo Pablo Alzamora de La Pascua, Director de Deportes con DNI. 07881037; Roberto Carlos Villafuerte Costa, Director Filial San Antonio con DNI. 07868174; Agustín Guevara Ballón, Director Filial Villa Deportiva con DNI. 08256601; Luis Rafael Espinar Barriga, Director Programa Adulto Mayor con DNI. 07807721; María del Rocío Balbuena Weinberger, Directora Vocal con DNI. 08273173; Felipe Armando Rodrigo Aguirre del Pino, Director Vocal con DNI. 10221981; y, María del Pilar Cáceres Loayza, Directora Vocal con DNI. 08260066 se llevó a cabo la sesión No. 004-24 del Consejo Directivo, convocada por la Directora Pro Secretaria mediante correo de fecha xxxxxxxxxx.</p><p>&nbsp;</p>',
        observacion:
            '<p>Concluida la reunión, se dio inmediata lectura a la presente Acta Nro. XXX-XXXX la misma que fue aprobada por unanimidad de los presentes, disponiéndose la inmediata formalización, cumplimiento y ejecución de todos los acuerdos adoptados.</p><p>No habiendo otro asunto por tratar, se levantó la sesión; siendo las veintidós horas con treinta minutos.</p>',
        publicado: true
    },
    'Gestion-acuerdo': {
        periodo: '2024-2026',
        nroacuerdo: 0,
        titulo: 'Escriba el titulo para esta solicitud…',
        antecedente: 'El CRL actualmente cuenta con...',
        propuesta: 'Se acordó aprobar...',
        proponente: 'A propuesta...',
        observacion: '',
        centrocosto: 0,
        sede: '',
        area: '',
        director: '',
        jefatura: '',
        funcionario: '',
        funcionario_nombre: '',
        revisor: '',
        revisor_nombre: '',
        digitador: '',
        digitador_nombre: '',
        proveedor: 'Digite el ruc…',
        montosoles: 0,
        montodolares: 0,
        presupuestado: true,
        pptoDisponible: true,
        transferencia: false,
        fechaInicio: new Date(),
        fechaFin: new Date(),
        tipo: 'ACUERDO', // ACUERDO | INFORME
        categoria: 'Escriba tipo',
        estado: 'REGISTRADO', // REGISTRADO | ACTUALIZADO | EN REVISIÓN | MODIFICADO | CANCELADO | OBSERVADO | APROBADO
        link: '',
        confidencial: false,
        publicado: false
    }
    // Agrega más modelos según sea necesario
};

// Función para obtener la plantilla de objeto según el modelo seleccionado
const getEmptyRegistro = (activeIndex) => {
    const modelName = modelosPorPagina[activeIndex]; // Suponiendo que tienes un array de modelosPorPagina
    return modelosTemplates[modelName] || {}; // Devuelve la plantilla correspondiente o un objeto vacío si no se encuentra
};

const modelosPorPagina = {
    0: 'Gestion-acta',
    1: 'Gestion-acuerdo',
    2: 'Gestion-acuerdo',
    3: 'Gestion-incidencia'
    // Agrega más páginas y modelos según sea necesario
};

const initRegistros = (activeIndex, data) => {
    const filters = [
        (item) => item.status,
        (item) => item.estado !== 'APROBADO' && item.estado !== 'OBSERVADO' && item.status,
        (item) => (item.estado === 'APROBADO' || item.estado === 'OBSERVADO') && item.status && item.publicado,
        (item) => item.status
    ];
    return data.datos.filter(filters[activeIndex]);
};

const GestionAcuerdo = () => {
    /* by Fernando */
    const navigate = useNavigate();

    const { user, accesos } = useContext(AuthContext);
    const [activeIndex, setActiveIndex] = useState(0);

    const [registro, setRegistro] = useState(getEmptyRegistro(activeIndex));
    const [registros, setRegistros] = useState([]);
    const [actas, setActas] = useState([]);
    const [actasAll, setActasAll] = useState([]);
    const [updateKey, setUpdateKey] = useState(0);

    const [registroDialog, setRegistroDialog] = useState(false);
    const [deleteRegistroDialog, setDeleteRegistroDialog] = useState(false);
    const [deleteRegistrosDialog, setDeleteRegistrosDialog] = useState(false);

    const [selectedRegistros, setSelectedRegistros] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');

    const toast = useRef(null);
    const dt = useRef(null);

    const [maxNumero, setmaxNumero] = useState(null);
    const [maxNumeroInforme, setmaxNumeroInforme] = useState(null);
    const [maxNumeroAcuerdo, setmaxNumeroAcuerdo] = useState(null);

    const [centrodecosto, setCentrodecosto] = useState(null);
    const [dropcentrodecosto, setDropCentrodecosto] = useState([]);

    const [razonsocial, setRazonsocial] = useState('');
    const [isSolesSelected, setIsSolesSelected] = useState(true);

    const [categorias, setCategorias] = useState([]);
    const [displayTextoCategoria, setDisplayTextoCategoria] = useState(false);

    const [incidencia, setIncidencia] = useState({ situacion: '', descripcion: 'Ingrese descripción...', comentario: 'Ingrese comentario...' });

    const actualizarRegistroSegunModelo = (activeIndex) => {
        const emptyRegistroModelo = getEmptyRegistro(activeIndex);
        setRegistro(emptyRegistroModelo);
    };

    const getConsultaDOC = async (tipo, numero) => {
        const sharedService = new SharedService();
        const result = await sharedService.getConsultaDOC(tipo, numero);

        if (result && result.success) {
            setRazonsocial(result.data.razonSocial);
        } else {
            setRazonsocial(result.message);
        }
    };

    const fetchEncargado = async () => {
        const sharedService = new SharedService();
        try {
            let encargado;

            if (tieneAccesoModuloOConNivel4() || tieneAccesoModuloOConNivel5()) {
                encargado = await sharedService.recuperarData('Gestion-encargado');
            } else if (tieneAccesoModuloOConNivel3()) {
                encargado = await sharedService.recuperarData('Gestion-encargado', 'funcionario', user.documento);
            } else if (tieneAccesoModuloOConNivel2()) {
                encargado = await sharedService.recuperarData('Gestion-encargado', 'revisor', user.documento);
            } else if (tieneAccesoModuloOConNivel1()) {
                encargado = await sharedService.recuperarData('Gestion-encargado', 'digitador', user.documento);
            }

            if (encargado && encargado.datos.length > 0) {
                // Obtener los valores únicos de datos para centrodecosto
                const centrodecostoArray = [...new Set(encargado.datos)];
                setCentrodecosto(centrodecostoArray);

                // Obtener los valores únicos de centrodecosto
                const dropCentrodecostoArray = [...new Set(encargado.datos.map((item) => item.centrodecosto))];
                setDropCentrodecosto(dropCentrodecostoArray);
            }
        } catch (error) {
            console.error('Error al recuperar datos del encargado:', error);
            // Maneja el error según sea necesario
        }
    };

    const fetchData = async () => {
        const modelName = modelosPorPagina[activeIndex];
        if (!modelName) {
            console.error('Modelo no encontrado para el índice activo.');
            return;
        }
        const sharedService = new SharedService();
        try {
            // Limpiar la tabla de registros
            setRegistros([]);
            setSubmitted(false);

            let data;

            if (user.role === 'COLABORADOR_ROLE' || user.role === 'ADMIN_ROLE' || user.role === 'SUPER_ADMIN_ROLE') {
                data = await sharedService.recuperarData(modelName);
                if (tieneAccesoModuloOConNivel4() || tieneAccesoModuloOConNivel5()) {
                    if (modelName === 'Gestion-acuerdo') {
                        setmaxNumeroInforme(data.maxNumeroInforme);
                        setmaxNumeroAcuerdo(data.maxNumeroAcuerdo);

                        const filteredData = data.datos.filter(
                            (item) => item.status && (item.digitador === user.documento || item.estado === 'MODIFICADO' || item.estado === 'EN REVISIÓN' || item.estado === 'APROBADO' || item.estado === 'OBSERVADO')
                        );
                        data.datos = filteredData;

                        let arrayCategorias = [...new Set(filteredData.map((item) => item.categoria))];
                        arrayCategorias.sort();
                        setCategorias(arrayCategorias);
                    }
                } else if (tieneAccesoModuloOConNivel1() || tieneAccesoModuloOConNivel2() || tieneAccesoModuloOConNivel3()) {
                    if (modelName === 'Gestion-acta') {
                        const filteredData = data.datos.filter((item) => item.publicado && item.status);
                        data.datos = filteredData;
                    } else if (modelName === 'Gestion-acuerdo') {
                        setmaxNumeroInforme(data.maxNumeroInforme);
                        setmaxNumeroAcuerdo(data.maxNumeroAcuerdo);

                        if (tieneAccesoModuloOConNivel3()) {
                            data = await sharedService.recuperarData(modelName, 'funcionario', user.documento);
                        } else if (tieneAccesoModuloOConNivel2()) {
                            data = await sharedService.recuperarData(modelName, 'revisor', user.documento);
                        } else if (tieneAccesoModuloOConNivel1()) {
                            data = await sharedService.recuperarData(modelName, 'digitador', user.documento);
                        }

                        let arrayCategorias = [...new Set(data.datos.map((item) => item.categoria))];
                        arrayCategorias.sort();
                        setCategorias(arrayCategorias);
                    }
                } else {
                    console.error('Acceso no autorizado');
                    navigate('/access');
                }
            } else {
                console.error('Acceso no autorizado');
                navigate('/access');
            }

            const updatedRegistros = initRegistros(activeIndex, data);
            setRegistros(updatedRegistros);

            if (modelName === 'Gestion-acta') {
                const now = new Date();
                const filteredData = data.datos.filter((item) => item.publicado && item.status && new Date(item.fechaCierre) > now);
                setActas(filteredData);
                setActasAll(data.datos);
                setmaxNumero(data.maxNumero);
            }
        } catch (error) {
            console.error('Error al recuperar datos:', error);
        }
    };

    const tieneAccesoModuloOConNivel5 = () => {
        // Lógica para verificar si el usuario tiene acceso al módulo 'O' con nivel 4
        return accesos.datos.some((acceso) => acceso.modulo === 'O' && acceso.nivel === 5);
    };

    const tieneAccesoModuloOConNivel4 = () => {
        // Lógica para verificar si el usuario tiene acceso al módulo 'O' con nivel 4
        return accesos.datos.some((acceso) => acceso.modulo === 'O' && acceso.nivel === 4);
    };

    const tieneAccesoModuloOConNivel3 = () => {
        // Lógica para verificar si el usuario tiene acceso al módulo 'O' con nivel 3
        return accesos.datos.some((acceso) => acceso.modulo === 'O' && acceso.nivel === 3);
    };

    const tieneAccesoModuloOConNivel2 = () => {
        // Lógica para verificar si el usuario tiene acceso al módulo 'O' con nivel 2
        return accesos.datos.some((acceso) => acceso.modulo === 'O' && acceso.nivel === 2);
    };

    const tieneAccesoModuloOConNivel1 = () => {
        // Lógica para verificar si el usuario tiene acceso al módulo 'O' con nivel 1
        return accesos.datos.some((acceso) => acceso.modulo === 'O' && acceso.nivel === 1);
    };

    const handleExportToWord = (rowData) => {
        return () => {
            createWordDocument(rowData);
        };
    };

    useEffect(() => {
        actualizarRegistroSegunModelo(activeIndex);

        fetchEncargado();
        fetchData();
    }, [activeIndex, updateKey]);

    const openNew = () => {
        const newRegistro = getEmptyRegistro(activeIndex);
        newRegistro.digitador = user.documento;
        newRegistro.usuarioCreado = `${user.paterno} ${user.materno} ${user.nombre}`;

        setDisplayTextoCategoria(false);
        setRazonsocial('');
        setRegistro(newRegistro);
        setSubmitted(false);
        setRegistroDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRegistroDialog(false);
    };

    const hideDeleteRegistroDialog = () => {
        setDeleteRegistroDialog(false);
    };

    const hideDeleteRegistrosDialog = () => {
        setDeleteRegistrosDialog(false);
    };

    const saveRegistro = async () => {
        setSubmitted(true);
        if (!registro.periodo.trim()) return;

        try {
            const tipo = registro._id ? 'editado' : 'creado';
            await handleSave(tipo, [registro], `Registro ${tipo}`);
            setRegistroDialog(false);
            setRegistro(getEmptyRegistro(activeIndex));
        } catch (error) {
            console.error('Error al guardar el registro:', error);
        }
    };

    const handleSave = async (tipo, datos, mensaje) => {
        const modelName = modelosPorPagina[activeIndex];
        if (!modelName) {
            throw new Error('Modelo no encontrado para el índice activo.');
        }

        const sharedService = new SharedService();
        let response = null;

        // Grabación incidencia
        if (activeIndex === 2) {
            let result = await sharedService.insertManyData('Gestion-incidencia', [incidencia]);

            if (result.datos && result.datos.length > 0) {
                if (!Array.isArray(datos[0].incidencias)) {
                    datos[0].incidencias = [];
                }

                result.datos.forEach((incidencia) => {
                    datos[0].incidencias.push(incidencia._id);
                });
            }
        }

        // Grabación general
        if (tipo === 'creado') {
            response = await sharedService.insertManyData(modelName, datos);
        } else {
            response = await sharedService.actualizarData(modelName, datos);
        }

        if (response) {
            await fetchData();

            if (tipo === 'creado' || tipo === 'editado') {
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: mensaje, life: 3000 });
            } else if (tipo === 'eliminado') {
                toast.current.show({ severity: 'warn', summary: 'Eliminado', detail: mensaje, life: 3000 });
            } else if (tipo === 'accept') {
                toast.current.show({ severity: 'info', summary: 'Confirmado', detail: mensaje, life: 3000 });
            } else if (tipo === 'reject') {
                toast.current.show({ severity: 'warn', summary: 'Rechazado', detail: mensaje, life: 3000 });
            }

            // Forzar actualización de la tabla
            setUpdateKey((prevKey) => prevKey + 1);
        }
    };

    const editRegistro = (registro) => {
        if (activeIndex === 0) {
            setRegistro({ ...registro, fechaActa: new Date(registro.fechaActa), fechaCierre: new Date(registro.fechaCierre) });
        } else if (activeIndex === 1) {
            setRegistro({
                ...registro,
                fechaInicio: new Date(registro.fechaInicio),
                fechaFin: new Date(registro.fechaFin),
                usuarioEditado: `${user.paterno} ${user.materno} ${user.nombre}`,
                fechaEditado: new Date()
            });

            if (registro.proveedor !== '' && registro.proveedor.length === 11) {
                getConsultaDOC('ruc', registro.proveedor);
            }

            setDisplayTextoCategoria(true);
        } else if (activeIndex === 2) {
            setRegistro({
                ...registro,
                fechaInicio: new Date(registro.fechaInicio),
                fechaFin: new Date(registro.fechaFin)
            });

            if (registro.proveedor !== '' && registro.proveedor.length === 11) {
                getConsultaDOC('ruc', registro.proveedor);
            }
        }

        setRegistroDialog(true);
    };

    const confirmDeleteRegistro = (registro) => {
        registro.usuarioEditado = `${user.paterno} ${user.materno} ${user.nombre}`;
        registro.fechaEditado = new Date();

        setRegistro(registro);
        setDeleteRegistroDialog(true);
    };

    const deleteRegistro = async () => {
        try {
            const updatedRegistro = { ...registro, estado: 'CANCELADO', status: false };
            await handleSave('eliminado', [updatedRegistro], 'Registro eliminado');
            setDeleteRegistroDialog(false);
            setRegistro(getEmptyRegistro(activeIndex));
        } catch (error) {
            console.error('Error al eliminar el registro:', error);
        }
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteRegistrosDialog(true);
    };

    const deleteSelectedRegistros = () => {
        let _registros = registros.filter((val) => !selectedRegistros.includes(val));
        setRegistros(_registros);
        setDeleteRegistrosDialog(false);
        setSelectedRegistros([]);
        toast.current.show({ severity: 'success', summary: 'Exitoso', detail: 'Registros eliminados', life: 3000 });
    };

    const onInputChange = (e, name, value = null) => {
        const newValue = value !== null ? value : e.target ? e.target.value : e.value;

        setRegistro((prevRegistro) => {
            let updatedRegistro = { ...prevRegistro, [name]: name === 'categoria' ? newValue.toUpperCase() : newValue };

            if (name === 'centrocosto') {
                const selectedCentroCosto = centrodecosto.find((item) => item.centrodecosto === newValue);

                if (selectedCentroCosto) {
                    updatedRegistro = {
                        ...updatedRegistro,
                        sede: selectedCentroCosto.sede,
                        area: selectedCentroCosto.area,
                        jefatura: selectedCentroCosto.jefatura,
                        director: selectedCentroCosto.director,
                        funcionario: selectedCentroCosto.funcionario,
                        funcionario_nombre: selectedCentroCosto.funcionario_nombre,
                        revisor: tieneAccesoModuloOConNivel1() ? selectedCentroCosto.revisor : ``,
                        revisor_nombre: tieneAccesoModuloOConNivel1() ? selectedCentroCosto.revisor_nombre : ``,
                        digitador_nombre: tieneAccesoModuloOConNivel1() ? selectedCentroCosto.digitador_nombre : `${user.paterno} ${user.materno} ${user.nombre}`
                    };
                } else {
                    updatedRegistro = {
                        ...updatedRegistro,
                        sede: '',
                        area: '',
                        jefatura: '',
                        director: '',
                        funcionario: '',
                        funcionario_nombre: '',
                        revisor: '',
                        revisor_nombre: '',
                        digitador_nombre: ''
                    };
                }
            }

            if (name === 'proveedor' && newValue.length === 11) {
                getConsultaDOC('ruc', newValue);
            }

            if (name === 'montosoles') {
                updatedRegistro = { ...updatedRegistro, montodolares: 0 };
            } else if (name === 'montodolares') {
                updatedRegistro = { ...updatedRegistro, montosoles: 0 };
            }

            return updatedRegistro;
        });

        // Grabación incidencia
        if (activeIndex === 2) {
            setIncidencia((prevIncidencia) => {
                let updatedIncidencia = { ...prevIncidencia, acuerdoModelo: registro._id, [name]: name === 'situacion' ? newValue.toUpperCase() : newValue };

                return updatedIncidencia;
            });
        }
    };

    const handleEditorChange = (event, editor, name) => {
        const value = editor.getData(); // Obtener el contenido actual del editor
        setRegistro((prevRegistro) => ({
            ...prevRegistro,
            [name]: value // Actualiza el estado con el contenido del editor
        }));
    };

    const leftToolbarTemplate = () => {
        const condition =
            ((tieneAccesoModuloOConNivel4() || tieneAccesoModuloOConNivel5()) && activeIndex !== 2 && activeIndex !== 3) || ((tieneAccesoModuloOConNivel1() || tieneAccesoModuloOConNivel2() || tieneAccesoModuloOConNivel3()) && activeIndex === 1);

        return (
            <React.Fragment>
                <div className="my-2">
                    {condition && <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />}
                    {condition && <Button label="Eliminar" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedRegistros || !selectedRegistros.length} />}
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Exportar" icon="pi pi-download" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    const fechaBodyTemplate = (fecha) => {
        // Verificar si la fecha es válida
        if (fecha) {
            // Formatear la fecha según tus necesidades
            const formattedDate = new Date(fecha).toLocaleDateString('es-PE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            return formattedDate;
        } else {
            return 'Fecha no disponible'; // Devuelve una cadena vacía si la fecha es nula o indefinida
        }
    };

    const horaBodyTemplate = (fecha) => {
        // Verificar si la fecha es válida
        if (fecha) {
            // Formatear la fecha según tus necesidades
            const formattedDate = new Date(fecha).toLocaleDateString('es-PE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
            return formattedDate;
        } else {
            return 'Fecha no disponible'; // Devuelve una cadena vacía si la fecha es nula o indefinida
        }
    };

    const nroactaBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nro. acta</span>
                <strong>{rowData.nroacta}</strong>
            </>
        );
    };

    const nroacuerdoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Numerador</span>
                <strong>{rowData.nroacuerdo}</strong>
            </>
        );
    };

    const codigoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                <strong>{rowData.codigo}</strong>
            </>
        );
    };

    const tipoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tipo</span>
                <strong>{rowData.tipo}</strong>
            </>
        );
    };

    const periodoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Período</span>
                <span className={`product-badge periodo-${rowData.periodo}`}>{rowData.periodo}</span>
            </>
        );
    };

    const usuarioBodyTemplate = (rowData) => {
        if (rowData.usuarioCreado) {
            return (
                <>
                    <span className="p-column-title">Creador</span>
                    {rowData.usuarioCreado}
                </>
            );
        } else {
            return (
                <>
                    <span className="p-column-title">Creador</span>
                    {'SYSTEM'}
                </>
            );
        }
    };

    const estadoBody = (rowData) => {
        return <span className={`acuerdo-badge estado-` + (rowData.estado === 'EN REVISIÓN' ? 'EN_REVISIÓN' : `${rowData.estado}`)}>{rowData.estado}</span>;
    };

    const estadoFilter = (options) => {
        return <Dropdown value={options.value} options={estado} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={estadoItem} placeholder="Seleccione estado" className="p-column-filter" showClear />;
    };

    const estadoItem = (option) => {
        return <span className={`acuerdo-badge estado-` + (option === 'EN REVISIÓN' ? 'EN_REVISIÓN' : `${option}`)}>{option}</span>;
    };

    const situacionBody = (rowData) => {
        return <span className={`acuerdo-badge situacion-` + (rowData.situacion === 'EN EJECUCIÓN' ? 'EN_EJECUCIÓN' : `${rowData.situacion}`)}>{rowData.situacion}</span>;
    };

    const situacionFilter = (options) => {
        return <Dropdown value={options.value} options={situacion} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={situacionItem} placeholder="Seleccione situación" className="p-column-filter" showClear />;
    };

    const situacionItem = (option) => {
        return <span className={`acuerdo-badge situacion-` + (option === 'EN EJECUCIÓN' ? 'EN_EJECUCIÓN' : `${option}`)}>{option}</span>;
    };

    const publicadoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Publicado</span>
                <span className={`product-badge status-${rowData.publicado ? 'publicado' : 'no-publicado'}`}>{rowData.publicado ? 'PUBLICADO' : 'NO PUBLICADO'}</span>
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        const isNivelAcceso123 = () => tieneAccesoModuloOConNivel1() || tieneAccesoModuloOConNivel2() || tieneAccesoModuloOConNivel3();
        const isNivelAcceso45 = () => tieneAccesoModuloOConNivel4() || tieneAccesoModuloOConNivel5();
        const estadosPermitidos = ['REGISTRADO', 'ACTUALIZADO', 'MODIFICADO'];
        const estadosRevisiónModificado = ['EN REVISIÓN', 'MODIFICADO'];
        const estadosObservadoCancelado = ['OBSERVADO', 'CANCELADO'];

        const isGestion1 = (isNivelAcceso45() && activeIndex !== 2 && rowData.digitador === user.documento) || (isNivelAcceso123() && activeIndex === 1);
        const isDisabled1 = (activeIndex === 0 && rowData.publicado) || (isNivelAcceso123() && activeIndex === 1 && !estadosPermitidos.includes(rowData.estado) && (tieneAccesoModuloOConNivel2() ? rowData.digitador !== user.documento : true));

        const isGestion2 = activeIndex === 1 && isNivelAcceso45();
        const isDisabled2 = !estadosRevisiónModificado.includes(rowData.estado);
        const isDisabled3 = estadosRevisiónModificado.includes(rowData.estado) && rowData.nroacuerdo === 0;

        const isGestion3 = activeIndex === 2 && isNivelAcceso45() && estadosObservadoCancelado.includes(rowData.estado);
        const now = new Date();
        const filteredData = actasAll.filter((item) => {
            const fechaActa = new Date(item.fechaActa);

            // Ignorar horas y comparar solo fechas
            return item.publicado && item.status && fechaActa.setHours(0, 0, 0, 0) >= now.setHours(0, 0, 0, 0);
        });
        const isDisabled4 = !filteredData.some((acta) => acta._id === rowData.actaModelo);

        return (
            <div className="actions">
                <Button icon={rowData.estado === 'APROBADO' || rowData.estado === 'OBSERVADO' ? 'pi pi-eye' : 'pi pi-pencil'} className="p-button-rounded p-button-primary mr-2" onClick={() => editRegistro(rowData)} />

                {isGestion1 && <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mr-2" onClick={() => confirmDeleteRegistro(rowData)} disabled={isDisabled1} />}
                {activeIndex === 1 && <Button icon="pi pi-arrow-right-arrow-left" className="p-button-rounded p-button-help mr-2" onClick={() => confirm1(rowData, 'modifiedRegistro', 'modificar el tipo de la solicitud')} disabled={isDisabled1} />}
                {activeIndex === 0 && isNivelAcceso45() && <Button icon="pi pi-file" className="p-button-rounded p-button-help mr-2" onClick={handleExportToWord(rowData)} />}

                {isGestion2 && (
                    <>
                        <Button icon="pi pi-file-export" className="p-button-rounded p-button-warning mr-2" onClick={() => confirm1(rowData, 'clearActaModelo', 'devolver la solicitud')} disabled={isDisabled2} />
                        <Button icon="pi pi-check" className="p-button-rounded p-button-success mr-2" onClick={() => confirm1(rowData, 'approveRegistro', 'aprobar la solicitud')} disabled={isDisabled3} />
                        <Button icon="pi pi-times" className="p-button-rounded p-button-danger mr-2" onClick={() => confirm1(rowData, 'observedRegistro', 'denegar la solicitud')} disabled={isDisabled2} />
                    </>
                )}

                {isGestion3 && (
                    <>
                        <Button icon="pi pi-file-export" className="p-button-rounded p-button-warning mr-2" onClick={() => confirm1(rowData, 'clearActaModelo', 'devolver la solicitud')} disabled={isDisabled4} />
                        <Button icon="pi pi-wrench" className="p-button-rounded p-button-secondary mr-2" onClick={() => confirm1(rowData, 'reviewedRegistro', 'enviar la solicitud')} disabled={isDisabled4} />
                    </>
                )}
            </div>
        );
    };

    const getHeaderTitle = () => {
        switch (activeIndex) {
            case 0:
                return 'Gestión de actas';
            case 1:
            case 2:
                return 'Gestión de acuerdos e informes';
            case 3:
                return 'Gestión de incidencias';
            default:
                return '';
        }
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">{getHeaderTitle()}</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar..." />
            </span>
        </div>
    );

    const registroDialogFooter = (
        <>
            {activeIndex !== 2 && activeIndex !== 3 && registro.estado !== 'APROBADO' && registro.estado !== 'OBSERVADO' && registro.estado !== 'CANCELADO' && (
                <>
                    <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
                    <Button
                        label="Guardar"
                        icon="pi pi-check"
                        className="p-button-text"
                        onClick={saveRegistro}
                        disabled={(tieneAccesoModuloOConNivel1() || tieneAccesoModuloOConNivel2() || tieneAccesoModuloOConNivel3()) && activeIndex === 1 && (registro.estado === 'EN REVISIÓN' || registro.estado === 'MODIFICADO')}
                    />
                </>
            )}
        </>
    );

    const deleteRegistroDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRegistroDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteRegistro} />
        </>
    );

    const deleteRegistrosDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRegistrosDialog} />
            <Button label="Si" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedRegistros} />
        </>
    );

    const isRowSelectable = (event) => !event.data.publicado && event.data.status;

    const actaModeloBodyTemplate = (rowData) => {
        const isNivelAcceso12 = () => tieneAccesoModuloOConNivel1() || tieneAccesoModuloOConNivel2();

        const isDisabled = isNivelAcceso12() || rowData.publicado === false || (rowData.estado !== 'REGISTRADO' && rowData.estado !== 'ACTUALIZADO');

        // Busca en el array `actas` el objeto cuyo `_id` coincide con `rowData.actaModelo`
        const acta = actasAll.find((acta) => acta._id === rowData.actaModelo);

        // Devuelve `nroacta` si se encuentra, de lo contrario 'SIN ASIGNAR'
        const nroacta = acta ? acta.nroacta : 'SIN ASIGNAR';

        return (
            <>
                <span className="p-column-title">Acta</span>
                <span style={{ cursor: isDisabled ? 'default' : 'pointer' }}>{nroacta}</span>
            </>
        );
    };

    const dropdownEditor = (options) => {
        const isDisabled = !actas.length || options.rowData.publicado === false || (options.rowData.estado !== 'REGISTRADO' && options.rowData.estado !== 'ACTUALIZADO' && options.rowData.estado !== 'MODIFICADO');

        return (
            <Dropdown
                value={options.value}
                options={actas}
                onChange={(e) => confirm1(options.rowData, 'reviewedRegistro', 'enviar la solicitud', e.value)}
                optionLabel="nroacta"
                optionValue="_id"
                placeholder="SELECCIONAR"
                style={{ width: '80%' }}
                disabled={isDisabled}
            />
        );
    };

    const confirm1 = (rowData, action, mensaje, value) => {
        confirmDialog({
            message: `¿Estás seguro que deseas ${mensaje}?`,
            header: 'Confirmación',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: 'Sí',
            rejectLabel: 'No',
            accept: () => accept(rowData, action, value),
            reject: () => reject(rowData, action)
        });
    };

    const accept = async (rowData, action, value) => {
        const updatedRegistro = updateRegistro(rowData, action, value);
        // console.log('Registro actualizado (aceptado):', updatedRegistro);
        await handleSave('accept', [updatedRegistro], 'Registro enviado');
    };

    const reject = async (rowData, action) => {
        const updatedRegistro = updateRegistro(rowData, action);
        // console.log('Registro actualizado (rechazado):', updatedRegistro);
        await handleSave('reject', [updatedRegistro], 'Registro cancelado');
    };

    const updateRegistro = (rowData, action, value) => {
        let updatedRegistro = { ...rowData };

        switch (action) {
            case 'clearActaModelo':
                updatedRegistro.actaModelo = null;
                updatedRegistro.estado = 'ACTUALIZADO';
                updatedRegistro.usuarioEditado = `${user.paterno} ${user.materno} ${user.nombre}`;
                updatedRegistro.fechaEditado = new Date();
                break;
            case 'reviewedRegistro':
                updatedRegistro.actaModelo = value;
                updatedRegistro.estado = 'EN REVISIÓN';
                updatedRegistro.usuarioEditado = `${user.paterno} ${user.materno} ${user.nombre}`;
                updatedRegistro.fechaEditado = new Date();
                break;
            case 'modifiedRegistro':
                updatedRegistro.tipo = updatedRegistro.tipo === 'ACUERDO' ? 'INFORME' : 'ACUERDO';
                updatedRegistro.usuarioEditado = `${user.paterno} ${user.materno} ${user.nombre}`;
                updatedRegistro.fechaEditado = new Date();

                if ((tieneAccesoModuloOConNivel4() || tieneAccesoModuloOConNivel5()) && updatedRegistro.estado === 'EN REVISIÓN') {
                    updatedRegistro.estado = 'MODIFICADO';
                } else {
                    updatedRegistro.estado = 'ACTUALIZADO';
                }
                break;
            case 'cancelRegistro':
                updatedRegistro.status = false;
                updatedRegistro.estado = 'CANCELADO';
                updatedRegistro.usuarioEditado = `${user.paterno} ${user.materno} ${user.nombre}`;
                updatedRegistro.fechaEditado = new Date();
                break;
            case 'observedRegistro':
                updatedRegistro.estado = 'OBSERVADO';
                updatedRegistro.usuarioEditado = `${user.paterno} ${user.materno} ${user.nombre}`;
                updatedRegistro.fechaEditado = new Date();
                break;
            case 'approveRegistro':
                updatedRegistro.estado = 'APROBADO';
                updatedRegistro.usuarioAprobado = `${user.paterno} ${user.materno} ${user.nombre}`;
                updatedRegistro.fechaAprobado = new Date();
                break;
            default:
                break;
        }

        return updatedRegistro;
    };

    const customUploader = async (event) => {
        const file = event.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('codigo', registro.codigo);

        try {
            const response = await fetch('https://regatasadmin.regataslima.pe/apidropzone/files.php', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                const fileName = data.filename;
                onInputChange({ target: { value: fileName } }, 'link');
                toast.current.show({ severity: 'success', summary: 'Exitoso', detail: `Archivo subido con éxito: ${fileName}`, life: 3000 });
            } else {
                const errorData = await response.json();
                const error = errorData.error;
                console.error('Error al subir el archivo:', error);
                toast.current.show({ severity: 'warn', summary: 'Alerta', detail: `Error al subir el archivo: ${error}`, life: 3000 });
            }
        } catch (error) {
            console.error('Error al subir el archivo:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: `Error al subir el archivo: ${error}`, life: 3000 });
        }
    };

    const onCurrencyChange = () => {
        setIsSolesSelected((prevState) => !prevState);
        setRegistro((prevState) => ({
            ...prevState,
            montosoles: !isSolesSelected ? prevState.montosoles : 0,
            montodolares: isSolesSelected ? prevState.montodolares : 0
        }));
    };

    const toggleDisplayTexto1 = () => {
        setDisplayTextoCategoria(!displayTextoCategoria);
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <ConfirmDialog />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="AGENDA">
                            <DataTable
                                ref={dt}
                                value={registros}
                                selection={selectedRegistros}
                                onSelectionChange={(e) => setSelectedRegistros(e.value)}
                                key={updateKey}
                                dataKey="_id"
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25]}
                                globalFilter={globalFilter}
                                header={header}
                                responsiveLayout="scroll"
                                className="datatable-responsive"
                                isDataSelectable={isRowSelectable}
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                emptyMessage="No hay registros"
                            >
                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                <Column field="nroacta" header="Nro. acta" dataType="numeric" sortable body={nroactaBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="periodo" header="Período" sortable body={periodoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column
                                    field="fechaActa"
                                    header={<span className="p-column-title">Fecha acta</span>}
                                    dataType="date"
                                    sortable
                                    body={(rowData) => fechaBodyTemplate(rowData.fechaActa)} // Pasa solo el valor de fechaActa
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                ></Column>
                                <Column
                                    field="fechaCierre"
                                    header={<span className="p-column-title">Fecha de cierre</span>}
                                    dataType="date"
                                    sortable
                                    body={(rowData) => horaBodyTemplate(rowData.fechaCierre)} // Pasa solo el valor de fechaCierre
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                ></Column>
                                {/* <Column field="observacion" header="Observación" sortable body={observacionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                                <Column field="usuarioCreado" header="Creador" sortable body={usuarioBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column
                                    field="fechaCreado"
                                    header={<span className="p-column-title">Fecha creado</span>}
                                    dataType="date"
                                    sortable
                                    body={(rowData) => fechaBodyTemplate(rowData.fechaCreado)} // Pasa solo el valor de la fecha
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                ></Column>
                                <Column field="publicado" header="Publicado" body={publicadoBodyTemplate} sortable alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                {(tieneAccesoModuloOConNivel4() || tieneAccesoModuloOConNivel5()) && <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }}></Column>}
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="SOLICITUDES">
                            <DataTable
                                ref={dt}
                                value={registros}
                                selection={selectedRegistros}
                                onSelectionChange={(e) => setSelectedRegistros(e.value)}
                                key={updateKey}
                                dataKey="_id"
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25]}
                                globalFilter={globalFilter}
                                header={header}
                                responsiveLayout="scroll"
                                className="datatable-responsive"
                                isDataSelectable={isRowSelectable}
                                filterDisplay="menu"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                emptyMessage="No hay registros"
                            >
                                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                                <Column field="codigo" header="Código" sortable body={codigoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                {(tieneAccesoModuloOConNivel4() || tieneAccesoModuloOConNivel5()) && (
                                    <Column field="nroacuerdo" header="Numerador" dataType="numeric" sortable body={nroacuerdoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                )}
                                <Column field="tipo" header="Tipo" sortable body={tipoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="periodo" header="Período" sortable body={periodoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="actaModelo" header="Acta" sortable body={actaModeloBodyTemplate} editor={(options) => dropdownEditor(options)} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }} editable />
                                <Column
                                    field="estado"
                                    header="Estado"
                                    filterMenuStyle={{ width: '16rem' }}
                                    style={{ width: '10%', minWidth: '8rem' }}
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                    body={estadoBody}
                                    sortable
                                    filter
                                    filterElement={estadoFilter}
                                />
                                {/* <Column
                                    field="situacion"
                                    header="Situación"
                                    filterMenuStyle={{ width: '16rem' }}
                                    style={{ width: '10%', minWidth: '8rem' }}
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                    body={situacionBody}
                                    sortable
                                    filter
                                    filterElement={situacionFilter}
                                /> */}
                                <Column field="usuarioCreado" header="Creador" sortable body={usuarioBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column
                                    field="fechaCreado"
                                    header={<span className="p-column-title">Fecha creado</span>}
                                    dataType="date"
                                    sortable
                                    body={(rowData) => fechaBodyTemplate(rowData.fechaCreado)} // Pasa solo el valor de la fecha
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                ></Column>
                                <Column field="publicado" header="Publicado" body={publicadoBodyTemplate} sortable alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="RESUELTOS">
                            <DataTable
                                ref={dt}
                                value={registros}
                                selection={selectedRegistros}
                                onSelectionChange={(e) => setSelectedRegistros(e.value)}
                                key={updateKey}
                                dataKey="_id"
                                paginator
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25]}
                                globalFilter={globalFilter}
                                header={header}
                                responsiveLayout="scroll"
                                className="datatable-responsive"
                                isDataSelectable={isRowSelectable}
                                filterDisplay="menu"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                                emptyMessage="No hay registros"
                            >
                                {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
                                {/* <Column field="codigo" header="Código" sortable body={codigoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column> */}
                                <Column field="nroacuerdo" header="Numerador" dataType="numeric" sortable body={nroacuerdoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="tipo" header="Tipo" sortable body={tipoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="periodo" header="Período" sortable body={periodoBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="actaModelo" header="Acta" sortable body={actaModeloBodyTemplate} editor={(options) => dropdownEditor(options)} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }} editable />
                                <Column
                                    field="estado"
                                    header="Estado"
                                    filterMenuStyle={{ width: '16rem' }}
                                    style={{ width: '10%', minWidth: '8rem' }}
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                    body={estadoBody}
                                    sortable
                                    filter
                                    filterElement={estadoFilter}
                                />
                                <Column
                                    field="situacion"
                                    header="Situación"
                                    filterMenuStyle={{ width: '16rem' }}
                                    style={{ width: '10%', minWidth: '8rem' }}
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                    body={situacionBody}
                                    sortable
                                    filter
                                    filterElement={situacionFilter}
                                />
                                <Column field="usuarioCreado" header="Creador" sortable body={usuarioBodyTemplate} alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column
                                    field="fechaCreado"
                                    header={<span className="p-column-title">Fecha creado</span>}
                                    dataType="date"
                                    sortable
                                    body={(rowData) => fechaBodyTemplate(rowData.fechaCreado)} // Pasa solo el valor de la fecha
                                    alignHeader={'center'}
                                    bodyStyle={{ textAlign: 'center' }}
                                ></Column>
                                <Column field="publicado" header="Publicado" body={publicadoBodyTemplate} sortable alignHeader={'center'} bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '4rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>
                        </TabPanel>
                        {/* <TabPanel header="INCIDENCIAS"></TabPanel> */}
                    </TabView>

                    <Dialog header="Registro" visible={registroDialog} maximizable modal className="p-fluid" footer={registroDialogFooter} onHide={hideDialog}>
                        {activeIndex === 0 && (
                            <>
                                <div className="formgrid grid">
                                    <div className="field col-2">
                                        <label htmlFor="nroacta">Nro. acta {maxNumero ? maxNumero + 1 : null}</label>
                                        <InputNumber id="nroacta" value={registro.nroacta} onValueChange={(e) => onInputChange(e, 'nroacta')} autoFocus className={classNames({ 'p-invalid': submitted && !registro.nroacta })} />
                                        {submitted && !registro.nroacta && <small className="p-invalid">Número de acta es requerido.</small>}
                                    </div>

                                    <div className="field col-4">
                                        <label className="mb-3">Período</label>
                                        <div className="formgrid grid">
                                            {/* <div className="field-radiobutton col-6">
                                                <RadioButton inputId="periodo1" name="periodo" value="2022-2024" onChange={(e) => onInputChange(e, 'periodo')} checked={registro.periodo === '2022-2024'} />
                                                <label htmlFor="periodo1">2022-2024</label>
                                            </div> */}
                                            <div className="field-radiobutton col-6">
                                                <RadioButton inputId="periodo2" name="periodo" value="2024-2026" onChange={(e) => onInputChange(e, 'periodo')} checked={registro.periodo === '2024-2026'} />
                                                <label htmlFor="periodo2">2024-2026</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="field">
                                    <label htmlFor="descripcion">Descripción</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={registro.descripcion} // Inicializa con el valor actual del estado
                                        onChange={(event, editor) => handleEditorChange(event, editor, 'descripcion')}
                                        config={{
                                            toolbar: {
                                                items: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', '|', 'insertTable'],
                                                shouldNotGroupWhenFull: true // Evita que el toolbar se agrupe
                                            }
                                        }}
                                    />
                                </div>

                                <div className="field">
                                    <label htmlFor="observacion">Observación</label>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={registro.observacion} // Inicializa con el valor actual del estado
                                        onChange={(event, editor) => handleEditorChange(event, editor, 'observacion')}
                                        config={{
                                            toolbar: {
                                                items: ['heading', '|', 'bold', 'italic', '|', 'alignment', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', '|', 'insertTable'],
                                                shouldNotGroupWhenFull: true // Evita que el toolbar se agrupe
                                            },
                                            // Asegúrate de que el plugin de alineación esté habilitado
                                            alignment: {
                                                options: ['left', 'center', 'right', 'justify'] // Opciones de alineación
                                            }
                                        }}
                                    />
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-3">
                                        <label htmlFor="fechaCierre">Fecha de Cierre</label>
                                        <Calendar inputId="fechaCierre" showIcon showButtonBar value={registro.fechaCierre} onChange={(e) => onInputChange(e, 'fechaCierre')} dateFormat="dd/mm/yy" hourFormat="24" showTime hourMin={0} hourMax={23} />
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="fechaActa">Fecha de Acta</label>
                                        <Calendar inputId="fechaActa" showIcon showButtonBar value={registro.fechaActa} onChange={(e) => onInputChange(e, 'fechaActa')} dateFormat="dd/mm/yy" />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="publicado">Publicado</label>
                                        <br />
                                        <InputSwitch inputId="publicado" checked={registro.publicado} onChange={(e) => onInputChange(e, 'publicado')} />
                                    </div>
                                </div>
                            </>
                        )}
                        {activeIndex === 1 && (
                            <>
                                <div className="formgrid grid">
                                    <div className="field col-2">
                                        <label htmlFor="tipo">Tipo</label>
                                        <Dropdown value={registro.tipo} options={tipo} onChange={(e) => onInputChange(e, 'tipo')} placeholder="SELECCIONAR" disabled={registro.codigo !== undefined} />
                                    </div>

                                    {(tieneAccesoModuloOConNivel1() || tieneAccesoModuloOConNivel2() || tieneAccesoModuloOConNivel3()) && registro.codigo !== undefined && (
                                        <div className="inline bg-primary font-bold text-center p-2 border-round mx-2 custom-height">
                                            Código
                                            <br />
                                            {registro.codigo}
                                        </div>
                                    )}

                                    {(tieneAccesoModuloOConNivel4() || tieneAccesoModuloOConNivel5()) && registro.codigo && (
                                        <div className="field col-1">
                                            <label htmlFor="nroacuerdo">{registro.tipo === 'ACUERDO' ? `Acuerdo ${maxNumeroAcuerdo + 1}` : `Informe ${maxNumeroInforme + 1}`}</label>
                                            <InputNumber id="nroacuerdo" value={registro.nroacuerdo} onValueChange={(e) => onInputChange(e, 'nroacuerdo')} autoFocus className={classNames({ 'p-invalid': submitted && !registro.nroacuerdo })} />
                                            {submitted && !registro.nroacuerdo && <small className="p-invalid">Número de acuerdo es requerido.</small>}
                                        </div>
                                    )}

                                    <div className="field col-3">
                                        <label className="mb-3">Período</label>
                                        <div className="formgrid grid">
                                            {/* <div className="field-radiobutton col-6">
                                                <RadioButton inputId="periodo1" name="periodo" value="2022-2024" onChange={(e) => onInputChange(e, 'periodo')} checked={registro.periodo === '2022-2024'} />
                                                <label htmlFor="periodo1">2022-2024</label>
                                            </div> */}
                                            <div className="field-radiobutton col-6">
                                                <RadioButton inputId="periodo2" name="periodo" value="2024-2026" onChange={(e) => onInputChange(e, 'periodo')} checked={registro.periodo === '2024-2026'} />
                                                <label htmlFor="periodo2">2024-2026</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap justify-content-center gap-3">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="presupuestado" onChange={(e) => onInputChange(e, 'presupuestado', e.checked)} checked={registro.presupuestado} />
                                            <label htmlFor="presupuestado" className="ml-2">
                                                Presupuestado
                                            </label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="pptoDisponible" onChange={(e) => onInputChange(e, 'pptoDisponible', e.checked)} checked={registro.pptoDisponible} />
                                            <label htmlFor="pptoDisponible" className="ml-2">
                                                Ppto. Disponible
                                            </label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="transferencia" onChange={(e) => onInputChange(e, 'transferencia', e.checked)} checked={registro.transferencia} />
                                            <label htmlFor="transferencia" className="ml-2">
                                                Transferencia
                                            </label>
                                        </div>
                                    </div>

                                    <div className="field col-2">
                                        <label htmlFor="centrocosto">Centro de costo</label>
                                        {registro.estado !== 'EN REVISIÓN' && registro.estado !== 'MODIFICADO' && (
                                            <Dropdown value={registro.centrocosto} options={dropcentrodecosto} onChange={(e) => onInputChange(e, 'centrocosto')} placeholder="SELECCIONAR" />
                                        )}
                                        {(registro.estado === 'EN REVISIÓN' || registro.estado === 'MODIFICADO') && <InputText id="centrocosto" value={registro.centrocosto} disabled />}
                                    </div>
                                </div>

                                {registro.centrocosto > 0 && (
                                    <div className="formgrid grid">
                                        <div className="field col-2">
                                            <label htmlFor="sede">Sede/Filial</label>
                                            <InputText id="sede" value={registro.sede} disabled />
                                        </div>
                                        <div className="field col-3">
                                            <label htmlFor="area">Área</label>
                                            <InputText id="area" value={registro.area} disabled />
                                        </div>
                                        <div className="field col-3">
                                            <label htmlFor="jefatura">Jefatura</label>
                                            <InputText id="jefatura" value={registro.jefatura} disabled />
                                        </div>
                                        <div className="field col-4">
                                            <label htmlFor="director">Director</label>
                                            <InputText id="director" value={registro.director} disabled />
                                        </div>
                                    </div>
                                )}

                                {(tieneAccesoModuloOConNivel4() || tieneAccesoModuloOConNivel5()) && registro.estado === 'EN REVISIÓN' && (
                                    <div className="formgrid grid">
                                        <div className="field col">
                                            <label htmlFor="proponente">Proponente</label>
                                            <InputTextarea id="proponente" value={registro.proponente} onChange={(e) => onInputChange(e, 'proponente')} rows={2} cols={20} />
                                        </div>
                                    </div>
                                )}

                                <div className="formgrid grid">
                                    <div className="field col-4">
                                        <label htmlFor="titulo">Nota</label>
                                        <InputText id="titulo" value={registro.titulo} onChange={(e) => onInputChange(e, 'titulo')} required autoFocus className={classNames({ 'p-invalid': submitted && !registro.titulo })} />
                                    </div>

                                    <div className="field col-2">
                                        <label htmlFor="categoria">{registro.tipo === 'ACUERDO' ? 'Tipo de acuerdo' : 'Tipo de informe'}</label>
                                        <div className="p-inputgroup flex-1">
                                            <Button icon={displayTextoCategoria ? 'pi pi-search' : 'pi pi-pencil'} className="p-button-primary" onClick={toggleDisplayTexto1} />

                                            {displayTextoCategoria && (
                                                <InputText
                                                    id="categoria"
                                                    value={registro.categoria}
                                                    onChange={(e) => onInputChange(e, 'categoria')}
                                                    autoFocus
                                                    className={classNames({ 'p-invalid': submitted && !registro.categoria })}
                                                    style={{ textTransform: 'uppercase' }}
                                                />
                                            )}

                                            {!displayTextoCategoria && <Dropdown value={registro.categoria} options={categorias} onChange={(e) => onInputChange(e, 'categoria')} placeholder="SELECCIONAR" />}
                                        </div>
                                    </div>

                                    <div className="field col-2">
                                        <label htmlFor="fechaInicio">Fecha de Inicio</label>
                                        <Calendar inputId="fechaInicio" showIcon showButtonBar value={registro.fechaInicio} onChange={(e) => onInputChange(e, 'fechaInicio')} dateFormat="dd/mm/yy" />
                                    </div>
                                    <div className="field col-2">
                                        <label htmlFor="fechaFin">Fecha de Fin</label>
                                        <Calendar inputId="fechaFin" showIcon showButtonBar value={registro.fechaFin} onChange={(e) => onInputChange(e, 'fechaFin')} dateFormat="dd/mm/yy" />
                                    </div>
                                    <div className="field col-2">
                                        <label htmlFor="publicado">Publicado</label>
                                        <br />
                                        <InputSwitch inputId="publicado" checked={registro.publicado} onChange={(e) => onInputChange(e, 'publicado')} />
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    {registro.tipo === 'ACUERDO' && (
                                        <div className="field col">
                                            <label htmlFor="antecedente">Antecedente</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={registro.antecedente} // Inicializa con el valor actual del estado
                                                onChange={(event, editor) => handleEditorChange(event, editor, 'antecedente')}
                                                config={{
                                                    toolbar: {
                                                        items: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', '|', 'insertTable'],
                                                        shouldNotGroupWhenFull: true // Evita que el toolbar se agrupe
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="propuesta">{registro.tipo === 'ACUERDO' ? 'Propuesta' : 'Descripción'}</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={registro.propuesta} // Inicializa con el valor actual del estado
                                            onChange={(event, editor) => handleEditorChange(event, editor, 'propuesta')}
                                            config={{
                                                toolbar: {
                                                    items: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', '|', 'insertTable'],
                                                    shouldNotGroupWhenFull: true // Evita que el toolbar se agrupe
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-3">
                                        <label htmlFor="proveedor">Proveedor</label>
                                        <InputText
                                            id="proveedor"
                                            value={registro.proveedor}
                                            onChange={(e) => onInputChange(e, 'proveedor')}
                                            required
                                            autoFocus
                                            maxLength={11}
                                            className={classNames({ 'p-invalid': submitted && !registro.proveedor })}
                                        />
                                        {razonsocial && (
                                            <p className="mt-2">
                                                <strong>{razonsocial}</strong>
                                            </p>
                                        )}
                                    </div>

                                    <div className="field col-3 flex flex-wrap justify-content-start">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="currency" checked={isSolesSelected} onChange={onCurrencyChange} />
                                            <label htmlFor="currency" className="p-checkbox-label ml-2">
                                                {isSolesSelected ? 'Monto en soles' : 'Monto en dólares'}
                                            </label>
                                        </div>
                                        {isSolesSelected ? (
                                            <InputNumber id="montosoles" value={registro.montosoles} onValueChange={(e) => onInputChange(e, 'montosoles')} mode="currency" currency="PEN" locale="es-PE" />
                                        ) : (
                                            <InputNumber id="montodolares" value={registro.montodolares} onValueChange={(e) => onInputChange(e, 'montodolares')} mode="currency" currency="USD" locale="en-US" />
                                        )}
                                    </div>

                                    {activeIndex === 1 && registro.codigo !== undefined && registro.estado !== 'APROBADO' && registro.estado !== 'OBSERVADO' && registro.estado !== 'CANCELADO' && (
                                        <div className="field col-2">
                                            <label htmlFor="file">
                                                {registro.link ? (
                                                    <a href={`https://regatasadmin.regataslima.pe/apidropzone/upload/${registro.link}`} target="_blank" rel="noopener noreferrer">
                                                        Anexo
                                                    </a>
                                                ) : (
                                                    'Anexo'
                                                )}
                                            </label>
                                            <FileUpload name="file" accept="application/pdf" customUpload uploadHandler={customUploader} mode="basic" auto chooseLabel={registro.link ? 'Cambiar archivo' : 'Subir archivo'} />
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                        {activeIndex === 2 && (
                            <>
                                <div className="formgrid grid">
                                    <div className="field col-2">
                                        <label htmlFor="tipo">Tipo</label>
                                        <InputText id="tipo" value={registro.tipo} disabled />
                                    </div>

                                    <div className="field col-1">
                                        <label htmlFor="nroacuerdo">{registro.tipo === 'ACUERDO' ? 'Acuerdo' : 'Informe'}</label>
                                        <InputText id="nroacuerdo" value={registro.nroacuerdo} disabled />
                                    </div>

                                    <div className="field col-2">
                                        <label htmlFor="periodo">Período</label>
                                        <InputText id="periodo" value={registro.periodo} disabled />
                                    </div>

                                    <div className="flex flex-wrap justify-content-center gap-3">
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="presupuestado" checked={registro.presupuestado} disabled />
                                            <label htmlFor="presupuestado" className="ml-2">
                                                Presupuestado
                                            </label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="pptoDisponible" checked={registro.pptoDisponible} disabled />
                                            <label htmlFor="pptoDisponible" className="ml-2">
                                                Ppto. Disponible
                                            </label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <Checkbox inputId="transferencia" checked={registro.transferencia} disabled />
                                            <label htmlFor="transferencia" className="ml-2">
                                                Transferencia
                                            </label>
                                        </div>
                                    </div>

                                    <div className="field col-2">
                                        <label htmlFor="centrocosto">Centro de costo</label>
                                        <InputText id="centrocosto" value={registro.centrocosto} disabled />
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-2">
                                        <label htmlFor="sede">Sede/Filial</label>
                                        <InputText id="sede" value={registro.sede} disabled />
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="area">Área</label>
                                        <InputText id="area" value={registro.area} disabled />
                                    </div>
                                    <div className="field col-3">
                                        <label htmlFor="jefatura">Jefatura</label>
                                        <InputText id="jefatura" value={registro.jefatura} disabled />
                                    </div>
                                    <div className="field col-4">
                                        <label htmlFor="director">Director</label>
                                        <InputText id="director" value={registro.director} disabled />
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="proponente">Proponente</label>
                                        <InputTextarea id="proponente" value={registro.proponente} rows={2} cols={20} disabled />
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-4">
                                        <label htmlFor="titulo">Nota</label>
                                        <InputText id="titulo" value={registro.titulo} disabled />
                                    </div>
                                    <div className="field col-2">
                                        <label htmlFor="categoria">{registro.tipo === 'ACUERDO' ? 'Tipo de acuerdo' : 'Tipo de informe'}</label>
                                        <InputText id="categoria" value={registro.categoria} disabled />
                                    </div>
                                    <div className="field col-2">
                                        <label htmlFor="fechaInicio">Fecha de Inicio</label>
                                        <Calendar inputId="fechaInicio" showIcon showButtonBar value={registro.fechaInicio} dateFormat="dd/mm/yy" disabled />
                                    </div>
                                    <div className="field col-2">
                                        <label htmlFor="fechaFin">Fecha de Fin</label>
                                        <Calendar inputId="fechaFin" showIcon showButtonBar value={registro.fechaFin} dateFormat="dd/mm/yy" disabled />
                                    </div>
                                    <div className="field col-2">
                                        <label htmlFor="publicado">Publicado</label>
                                        <br />
                                        <InputSwitch inputId="publicado" checked={registro.publicado} disabled />
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    {registro.tipo === 'ACUERDO' && (
                                        <div className="field col">
                                            <label htmlFor="antecedente">Antecedente</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={registro.antecedente} // Inicializa con el valor actual del estado
                                                onChange={(event, editor) => handleEditorChange(event, editor, 'antecedente')}
                                                onReady={(editor) => {
                                                    // Definir el modo de solo lectura en función de los roles
                                                    const isReadOnly = tieneAccesoModuloOConNivel1() || tieneAccesoModuloOConNivel2() || tieneAccesoModuloOConNivel3();

                                                    if (isReadOnly) {
                                                        editor.enableReadOnlyMode('myReadOnlyLock');
                                                    } else {
                                                        editor.disableReadOnlyMode('myReadOnlyLock');
                                                    }
                                                }}
                                                config={{
                                                    toolbar: {
                                                        items: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', '|', 'insertTable'],
                                                        shouldNotGroupWhenFull: true // Evita que el toolbar se agrupe
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className="field col">
                                        <label htmlFor="propuesta">{registro.tipo === 'ACUERDO' ? 'Propuesta' : 'Descripción'}</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={registro.propuesta} // Inicializa con el valor actual del estado
                                            onChange={(event, editor) => handleEditorChange(event, editor, 'propuesta')}
                                            onReady={(editor) => {
                                                // Definir el modo de solo lectura en función de los roles
                                                const isReadOnly = tieneAccesoModuloOConNivel1() || tieneAccesoModuloOConNivel2() || tieneAccesoModuloOConNivel3();

                                                if (isReadOnly) {
                                                    editor.enableReadOnlyMode('myReadOnlyLock');
                                                } else {
                                                    editor.disableReadOnlyMode('myReadOnlyLock');
                                                }
                                            }}
                                            config={{
                                                toolbar: {
                                                    items: ['heading', '|', 'bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'blockQuote', '|', 'insertTable'],
                                                    shouldNotGroupWhenFull: true // Evita que el toolbar se agrupe
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="formgrid grid">
                                    <div className="field col-4">
                                        <label htmlFor="proveedor">Proveedor</label>
                                        <InputText id="proveedor" value={registro.proveedor} disabled />
                                        {razonsocial && (
                                            <p className="mt-2">
                                                <strong>{razonsocial}</strong>
                                            </p>
                                        )}
                                    </div>
                                    {registro.montosoles > 0 && (
                                        <div className="field col-2">
                                            <label htmlFor="montosoles">Monto en soles</label>
                                            <InputNumber id="montosoles" value={registro.montosoles} mode="currency" currency="PEN" locale="es-PE" disabled />
                                        </div>
                                    )}
                                    {registro.montodolares > 0 && (
                                        <div className="field col-2">
                                            <label htmlFor="montodolares">Monto en dólares</label>
                                            <InputNumber id="montodolares" value={registro.montodolares} mode="currency" currency="USD" locale="en-US" disabled />
                                        </div>
                                    )}
                                    {registro.link && (
                                        <div className="field col-2">
                                            <label htmlFor="link">Anexo</label>
                                            <a href={`https://regatasadmin.regataslima.pe/apidropzone/upload/${registro.link}`} target="_blank" rel="noopener noreferrer">
                                                Archivo adjuntado
                                            </a>
                                        </div>
                                    )}
                                </div>

                                {registro.estado === 'APROBADO' && (
                                    <Card title={`STATUS ${registro.tipo} ${registro.nroacuerdo}`}>
                                        {(() => {
                                            const lastIncidencia = registro.incidencias[registro.incidencias.length - 1];

                                            return (
                                                (!registro.incidencias.length || lastIncidencia.situacion !== 'CULMINADO') && (
                                                    <>
                                                        <div className="formgrid grid">
                                                            <div className="field col-2">
                                                                <label htmlFor="situacion">Situación</label>
                                                                <Dropdown value={incidencia.situacion} options={situacion} onChange={(e) => onInputChange(e, 'situacion')} placeholder="SELECCIONAR" />
                                                            </div>
                                                        </div>

                                                        <div className="formgrid grid">
                                                            <div className="field col">
                                                                <label htmlFor="descripcion">Descripción</label>
                                                                <InputTextarea id="descripcion" value={incidencia.descripcion} onChange={(e) => onInputChange(e, 'descripcion')} required rows={5} cols={20} />
                                                            </div>
                                                            <div className="field col">
                                                                <label htmlFor="comentario">Comentario</label>
                                                                <InputTextarea id="comentario" value={incidencia.comentario} onChange={(e) => onInputChange(e, 'comentario')} required rows={5} cols={20} />
                                                            </div>
                                                        </div>

                                                        <div className="formgrid grid">
                                                            <div className="field col-2">
                                                                <Button label="Guardar" icon="pi pi-check" onClick={saveRegistro} disabled={submitted} />
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            );
                                        })()}

                                        {registro.incidencias.length > 0 && (
                                            <DataTable value={registro.incidencias} paginator rows={10}>
                                                <Column field="situacion" header="Situación" alignHeader="center" bodyStyle={{ textAlign: 'center' }} />
                                                <Column field="descripcion" header="Descripción" alignHeader="center" bodyStyle={{ textAlign: 'center' }} />
                                                <Column field="comentario" header="Comentario" alignHeader="center" bodyStyle={{ textAlign: 'center' }} />
                                                <Column field="fechaCreado" header="Fecha" alignHeader="center" bodyStyle={{ textAlign: 'center' }} body={(rowData) => horaBodyTemplate(rowData.fechaCreado)} />
                                            </DataTable>
                                        )}
                                    </Card>
                                )}
                            </>
                        )}
                    </Dialog>

                    <Dialog visible={deleteRegistroDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteRegistroDialogFooter} onHide={hideDeleteRegistroDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {registro && activeIndex === 0 && (
                                <span>
                                    ¿Estás seguro de que quieres eliminar el acta <b>{registro.nroacta}</b> del período <b>{registro.periodo}</b>?
                                </span>
                            )}
                            {registro && activeIndex === 1 && (
                                <span>
                                    ¿Estás seguro de que quieres eliminar la solicitud <b>{registro.codigo}</b> del período <b>{registro.periodo}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteRegistrosDialog} style={{ width: '450px' }} header="Confirmar" modal footer={deleteRegistrosDialogFooter} onHide={hideDeleteRegistrosDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {registro && <span>¿Está seguro de que desea eliminar los registros seleccionados?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

export default GestionAcuerdo;
