import React, { useState } from 'react';

const TablePicker = ({ onPick }) => {
    const [rows, setRows] = useState(0);
    const [cols, setCols] = useState(0);

    const handleMouseOver = (r, c) => {
        setRows(r);
        setCols(c);
    };

    const handleClick = () => {
        onPick(rows, cols);
    };

    return (
        <div
            className="table-picker"
            onMouseLeave={() => {
                setRows(0);
                setCols(0);
            }}
        >
            {[...Array(10)].map((_, rowIndex) => (
                <div className="table-picker-row" key={rowIndex}>
                    {[...Array(10)].map((_, colIndex) => (
                        <div key={colIndex} className={`table-picker-cell ${rowIndex < rows && colIndex < cols ? 'selected' : ''}`} onMouseOver={() => handleMouseOver(rowIndex + 1, colIndex + 1)} onClick={handleClick}></div>
                    ))}
                </div>
            ))}
            {rows > 0 && cols > 0 && (
                <div className="table-picker-tooltip">
                    {rows} x {cols}
                </div>
            )}
        </div>
    );
};

export default TablePicker;
