// auth/PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { ProgressSpinner } from 'primereact/progressspinner';

const PrivateRoute = ({ children }) => {
    const { user, loading, error } = useContext(AuthContext);

    if (loading) {
        return (
            <div className="spinner-container">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>
        );
    }

    // if (error) {
    //     return <Navigate to="/error" />;
    // }

    return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
